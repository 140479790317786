import React, { useEffect, useRef } from "react";
import { motion, useInView, useAnimation } from "framer-motion";

import "./About.css";

function App() {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    const mainControls = useAnimation();

    useEffect(() => {
        if (isInView) {
            mainControls.start("visible");
        }
    }, [isInView]);

    return (
        <section id="about">
            <div>
                <div id="about-title">
                    <motion.div
                        ref={ref}
                        variants={{
                            hidden: { opacity: 0, y: 75, x: 0 },
                            visible: { opacity: 1, y: 0, x: 0 },
                        }}
                        initial="hidden"
                        animate={mainControls}
                        transition={{ duration: 0.4, delay: 0 }}
                    >
                        <h1>xsiadron</h1>
                    </motion.div>
                    <motion.div
                        ref={ref}
                        variants={{
                            hidden: { opacity: 0, y: 50, x: 0 },
                            visible: { opacity: 1, y: 0, x: 0 },
                        }}
                        initial="hidden"
                        animate={mainControls}
                        transition={{ duration: 0.4, delay: 0.1 }}
                    >
                        <small>
                            Indie
                            <ul>
                                <span>&#9898;</span>
                                <li className="underline" id="game-button">
                                    game
                                </li>
                                <span>&#9898;</span>
                                <li className="underline">app</li>
                                <span>&#9898;</span>
                                <li className="underline">web</li>
                            </ul>
                            developer
                        </small>{" "}
                    </motion.div>
                    <motion.div
                        ref={ref}
                        variants={{
                            hidden: { opacity: 0, y: 50, x: 50 },
                            visible: { opacity: 1, y: 0, x: 0 },
                        }}
                        initial="hidden"
                        animate={mainControls}
                        transition={{ duration: 0.4, delay: 0.2 }}
                    >
                        <p>
                            <b>Hey!</b> I'm a creative programmer from Poland,
                            actively contributing on a global scale.
                            <br />
                            Every project is entirely created by me,
                            nevertheless I manage perfectly well in a team.
                            <br />
                            <br />
                            <div id="build_projects_text_block">
                                My projects are built in
                                <div id="build_projects">
                                    <span>Unreal&nbsp;Engine</span>{" "}
                                    <span>Android&nbsp;Studio</span>{" "}
                                    <span>
                                        2D&nbsp;Graphical&nbsp;Softwares
                                    </span>{" "}
                                    <span>
                                        3D&nbsp;Graphical&nbsp;Softwares
                                    </span>{" "}
                                    <span>
                                        Audio&nbsp;&&nbsp;Video&nbsp;Editor&nbsp;Softwares
                                    </span>
                                    <span>&&nbsp;IDE&nbsp;Enviroments</span>
                                </div>
                            </div>
                        </p>
                        <br />
                    </motion.div>

                    <motion.div
                        ref={ref}
                        variants={{
                            hidden: { opacity: 0, y: 50, x: 50 },
                            visible: { opacity: 1, y: 0, x: 0 },
                        }}
                        initial="hidden"
                        animate={mainControls}
                        transition={{ duration: 0.4, delay: 0.3 }}
                    >
                        <p>
                            I'm writing in&ensp;
                            <span>C++</span> <span>C#</span>{" "}
                            <span>JavaScript/React&nbsp;+&nbsp;Node.js</span>{" "}
                            <span>SQL</span> <span>Python</span>{" "}
                            <span>Lua</span>
                            <span>Java</span> <span>&&nbsp;Kotlin</span>
                        </p>
                        <br />
                        <br />
                    </motion.div>

                    <motion.div
                        ref={ref}
                        variants={{
                            hidden: { opacity: 0, y: 0, x: 50 },
                            visible: { opacity: 1, y: 0, x: 0 },
                        }}
                        initial="hidden"
                        animate={mainControls}
                        transition={{ duration: 1, delay: 0.75 }}
                    >
                        <p>
                            Do you want to collaborate with me?{" "}
                            <a href="mailto:lukasz.frydrych@xsiadron.com">
                                <b>Feel&nbsp;free&nbsp;to&nbsp;message!</b>
                            </a>
                        </p>{" "}
                    </motion.div>
                </div>
            </div>
        </section>
    );
}

export default App;
